export function ArrayUnique(array) {
    return Array.from(new Set(array));
}

export function convertHhMmSsToSeconds(str) {
    const arr = str.split(':').reverse();

    return arr.map((item, index) => {
        if (index === 0) {
            return item;
        }
        return item * (60 ** index);
    }).reduce((pre, cur) => {
        return parseInt(pre) + parseInt(cur);
    })
}

export function padZero(_value = 0, digit = 2) {
    const value = parseInt(_value, 10);
    if (!value) return '00';

    if (String(value).length > digit) return null;

    return String(new Array(digit).fill('0').join('') + String(value)).slice(digit * -1);
}

export function convertSecondsToHhMmSs(_sec = 0) {
    if (!_sec) return '0:00';

    const sec = parseInt(_sec, 10);
    if (sec <= 0) return '0:00';

    let h = sec / 3600;
    let m = sec % 3600 / 60;
    let s = sec % 60;

    h = Math.floor(Math.abs(h));
    m = Math.floor(Math.abs(m));
    s = Math.floor(Math.abs(s));

    s = padZero(s);

    if (h > 0) {
        m = padZero(m);
        return `${h}:${m}:${s}`;
    }
    return `${m}:${s}`;
}