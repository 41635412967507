import React from 'react';
import './SongList.scss';

class SongList extends React.Component {
    render() {
        if (this?.props?.song && this.props.song.length > 0) {
            return (
                <ul className="SongList">
                    {this.renderItems()}
                </ul>
            )
        }
        return <div className="SongList">読込中</div>
    }

    renderItems(item) {
        return this.props.song.map((item, index) => {
            return (<li
                className={this.props.video_id === item.YouTube ? 'playing' : ''}
                key={index}
                onClick={() => { this.changeVideo(item.YouTube) }}
            >
                <div className="name">{item['曲名']}</div>
                <div className={item.YouTube ? 'play' : 'play disabled'}
                    value={item.YouTube}
                ><span className="doPlay">再生</span><span className="playing-now">再生中</span></div>
            </li>)
        })
    }

    changeVideo(video_id) {
        console.log('子', video_id)
        if (!video_id) return;
        return this.props.changeVideo(video_id);
    }
}

export default SongList;
