import moment from 'moment';
import 'moment-timezone';


class DataClient {
    constructor(raw) {
        this.raw = this.setRaw(raw);
        this.initFilter();
        this.initSort();
    }

    setRaw(_raw) {
        const raw = _raw?.data?.items.reverse().reverse();
        return raw.map(song => {
            let kashi;
            if (song.hasOwnProperty('歌詞')) {
                kashi = song['歌詞'].split(/\r\n|\n/).map(item => {
                    return item.split('#');
                });
            }
            return {
                ...song,
                kashi,
            };
        })
    }

    initFilter() {
        this.filter = {
            member: [''],
            duration: {
                start: '',
                end: '',
            },
            title: '',
        }
    }

    initSort() {
        this.sort = {
            key: '',
            is_asc: true,
        }
    }

    setCondition(conditions) {
        this.filter = conditions.filter;
        this.sort = conditions.sort;
    }

    resetCondition() {
        this.sort = {
            key: '',
            is_asc: true,
        }

        this.filter = {
            member: [''],
            duration: {
                start: '',
                end: '',
            },
            title: '',
        }
    }

    song() {
        const filtered = this.raw
            .filter(song => this.filterTitle(song))
            .filter(song => this.filterMember(song))
            .filter(song => this.filterDuration(song))
            .sort((a, b) => this.sortPublish(a, b));
        if (this.sort.is_asc) {
            return filtered.reverse();
        }
        return filtered;
    }

    filterTitle(song) {
        const title = this.filter.title;
        return new RegExp(title, 'i').test(song['曲名']);
    }

    filterMember(song) {
        const member = song['メンバー'];
        if (this.filter.member.length === 0) {
            return song;
        }
        return this.filter.member.every(item => {
            return new RegExp(item).test(member);
        });
    }

    filterDuration(song) {
        const publish = song['音源公開日'];
        const start = this.filter.duration.start;
        const end = this.filter.duration.end;

        if (start && end) {
            return moment(publish).isBetween(start, end, 'day', '[]');
        }
        if (start) {
            return moment(publish).isSameOrAfter(start);
        }
        if (end) {
            return moment(publish).isSameOrBefore(end);
        }

        return true;
    }

    sortPublish(a, b) {
        const a_unix = moment(a['音源公開日']).unix();
        const b_unix = moment(b['音源公開日']).unix();
        if (a_unix > 0 && b_unix) {
            return a_unix - b_unix;
        }
        return -1;
    }
}

export default DataClient;
