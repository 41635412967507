import React from 'react';
import './Splash.scss';

export default class Splash extends React.Component {
    constructor() {
        super();
        this.state = {
        }

        const Holo = 'HoloPlayer';
    }

    render() {
        return (<div
            className={this.props.video_id ? 'splash is-hide' : 'splash'}
            style={{ backgroundColor: this.props.playerInfo.primary }}
        >
            <div className="name">{this.props.playerInfo.player_name}</div>
            <div className="pulse-container">
                <div className="pulse-bubble pulse-bubble-1"></div>
                <div className="pulse-bubble pulse-bubble-2"></div>
                <div className="pulse-bubble pulse-bubble-3"></div>
            </div>
        </div>)
    }
}
