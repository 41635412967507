import React from 'react';
import './Controller.scss';
import { convertSecondsToHhMmSs } from '../lib/misc'
import SongDetail from './SongDetail';

class Controller extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: true,
            title: '',
            singer: '',
        }
    }

    renderPlayedAndDuration() {
        const playedSeconds = convertSecondsToHhMmSs(this.props.playedSeconds);
        const _leftSeconds = convertSecondsToHhMmSs(this.props.duration - this.props.playedSeconds);
        const leftSeconds = this.props.duration - this.props.playedSeconds > 0 ? '-' + _leftSeconds : _leftSeconds;

        return (<div className="playedAndDuration">
            <div className="played">{playedSeconds}</div>
            <div className="left">{leftSeconds}</div>
        </div>);
    }

    getSongDetail() {
        if (!this.props?.song.length) return null;
        const song = this.props.song.find(item => item.YouTube === this.props.video_id);
        return song;
    }

    renderDefault() {
        const song_detail = this.getSongDetail();
        if (!song_detail?.['曲名']) return;
        return (<div className="controller">
            <div className="toggle" onClick={() => { this.toggleController() }}>
                <div className="thumbnail" style={{
                    backgroundImage: `url('http://img.youtube.com/vi/${this.props.video_id}/mqdefault.jpg')`
                }}></div>
                <div className="title">
                    {this.getSongDetail()['曲名']}
                </div>
            </div>
            <div className="back" onClick={() => { this.seekZero(); }}>先頭</div>
            <div className="next" onClick={() => { this.nextVideo() }}>次の曲</div>
        </div>);
    }



    renderOpen() {
        const song_detail = this.getSongDetail();
        if (!song_detail?.['曲名']) return;
        return (
            <div className="openedController">
                <div className="closer"
                    onTouchStart={() => { this.toggleController() }}
                    onClick={() => { this.toggleController() }}
                >
                    <div className="view">ー</div>
                    <div className="songDetail">
                        <div className="thumbnail" style={{ backgroundImage: `url('http://img.youtube.com/vi/${this.props.video_id}/mqdefault.jpg')` }}></div>
                        <div className="titleAndSinger">
                            <div className="title">
                                {song_detail['曲名']}
                            </div>
                            <div className="singer">
                                {song_detail['メンバー']}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body">
                    <SongDetail
                        song={this.props.song}
                        playedSeconds={this.props.playedSeconds}
                        seek={s => this.props.seek(s)}
                        video_id={this.props.video_id}
                        duration={this.props.duration}
                    ></SongDetail>

                </div>
                <div className="playController">
                    <div className="seekbar">
                        <input value={this.props.played} className="seekbarbody" id="seekbar" type="range" name="seekbar" min="0" max="1" step="0.001" onChange={(e) => { this.onChangeSeek(e) }}></input>
                    </div>
                    {this.renderPlayedAndDuration()}
                    <div className="bar">
                        <div className="beforeSong" onClick={e => this.handleClickLeftButton(e)}>
                            ＜＜
                        </div>
                        <div className="playAndPause" onClick={(e) => { this.onClickPlayAndPause(e) }}>
                            {(this.props.is_playing ? '| |' : '▶')}
                        </div>
                        <div className="nextSong" onClick={e => this.props.nextVideo(e)}>
                            ＞＞
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleClickLeftButton() {
        if (this.props.playedSeconds < 1) {
            this.props.prevVideo();
        }
        this.seekZero();
    }

    onClickPlayAndPause(e) {
        this.props.toggleIsPlaying();
    }

    onChangeSeek(e) {
        this.props.seek(e.target.value * this.props.duration);
    }

    render() {
        return (
            <div className={this.state.isOpen ? 'is-open parent' : 'is-closed parent'}>
                {this.renderOpen()}
                {this.renderDefault()}
            </div>
        )
    }

    toggleController() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    nextVideo() {
        this.props.nextVideo();
    }

    seekZero() {
        this.props.seekZero();
    }

    getVideoTitle() {
        let title;
        this.props.song.filter(song => {
            if (song.YouTube === this.props.video_id) {
                console.log(song);
                title = song['曲名'];
                return true;
            }
            return false;
        })
        return title;
    }
}

export default Controller;
