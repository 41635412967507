import React from 'react';
import './CollectionController.scss';
// import moment from 'moment';
import 'moment-timezone';
import { playerInfo } from '../lib/PlayerInfo';

class CollectionController extends React.Component {
    constructor() {
        super();
        this.playerInfo = playerInfo();
        this.conditions = {
            filter: {
                member: [''],
                duration: {
                    start: '',
                    end: '',
                },
                title: '',
            },
            sort: {
                key: '',
                is_asc: true,
            },
            is_sabi_mode: false,
        }
        this.state = { value: '', start: '', end: '', title: '', is_sabi_mode: false };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleChangeSabiMode = this.handleChangeSabiMode.bind(this);
    }

    handleChange(e) {
        this.setState({ value: e.target.value })
        this.filterMember(e.target.value);
    }

    handleChangeStart(e) {
        this.setState({ start: e.target.value })
        this.conditions.filter.duration.start = e.target.value;
        this.setCondition();
    }

    handleChangeEnd(e) {
        this.setState({ end: e.target.value })
        this.conditions.filter.duration.end = e.target.value;
        this.setCondition();
    }

    handleChangeTitle(e) {
        this.setState({ title: e.target.value })
        this.conditions.filter.title = e.target.value;
        this.setCondition();
    }

    handleChangeSabiMode(e) {
        this.setState({ is_sabi_mode: e.target.checked })
        this.conditions.is_sabi_mode = e.target.checked;
        this.props.changeSabiMode(e.target.checked);
    }

    render() {
        return (<ul className="CollectionController">
            {/* <li onClick={() => { this.resetCondition() }}>すべて</li> */}
            {/* <li onClick={() => { this.filterMember('夜空メル') }}>夜空メル</li> */}
            <li><input type="text" placeholder={this.playerInfo.placeholder.song} value={this.state.title} onChange={this.handleChangeTitle}></input></li>
            <li><input type="text" placeholder={this.playerInfo.placeholder.singer} value={this.state.value} onChange={this.handleChange}></input></li>
            <li>
                <input type='date' name="start" placeholder="開始日" value={this.state.start} onChange={this.handleChangeStart}></input>
                〜
                <input type='date' name="end" placeholder="終了日" value={this.state.end} onChange={this.handleChangeEnd}></input>
                <button onClick={() => { this.clearDuration() }}>クリア</button>
            </li>
            <li>
                <label>
                    <input type="checkbox" value={this.state.is_sabi_mode} onChange={this.handleChangeSabiMode}></input>サビのみ
                </label>
            </li>
        </ul>)
    }

    clearDuration() {
        this.setState({
            start: '',
            end: '',
        })
    }

    resetCondition() {
        this.conditions = {
            filter: {
                member: [''],
                duration: {
                    start: '',
                    end: '',
                }
            },
            sort: {
                key: '',
                is_asc: true,
            }
        }
        return this.props.resetCondition();
    }

    filterMember(member) {
        this.conditions.filter.member = member.split(',').filter(item => item !== '');
        this.setCondition();
    }

    setCondition() {
        return this.props.setCondition(this.conditions);
    }
}

export default CollectionController;
