import React from 'react';
import './Player.css';
import ReactPlayer from 'react-player'

class Player extends React.Component {
    constructor() {
        super();
        this._onStateChange = this._onStateChange.bind(this);
        this.ReactPlayerRef = React.createRef();
        this.state = {
            volume: 1,
            fadeDuration: 1500,//milliseconds,
        }
        this.timeout = null;
        this.interval = null;
    }

    componentDidMount() {
        this.seekbar = document.getElementById('seekbar');
    }

    render() {
        if (!this.props.video_id) return '';
        return <div className="Player">
            <ReactPlayer
                url={'https://www.youtube.com/watch?v=' + this.props.video_id}
                playing={this.props.is_playing}
                width="100%"
                height="100%"
                controls={true}
                onDuration={(e) => { this._onDuration(e) }}
                onStart={(e) => { this._onStart(e) }}
                onEnded={(e) => { this._onEnded(e) }}
                onProgress={(e) => this._onProgress(e)}
                onReady={(e) => { this._onReady(e) }}
                ref={this.ReactPlayerRef}
                volume={this.state.volume}
                progressInterval={500}
            />
        </div>;
    }

    _onDuration(duration) {
        this.props.setDuration(duration);
    }

    seekZero() {
        this.ReactPlayerRef.current.seekTo(0);
    }

    seek(s) {
        this.ReactPlayerRef.current.seekTo(parseInt(s, 10));
    }

    setVolume(volume) {
        this.setState({
            volume,
        });
    }

    fadeOut() {
        const current_volume = this.state.volume;//0-1
        const duration = this.state.fadeDuration;//ms
        const interval = 100;//ms
        const volume_step = current_volume / duration * interval;

        this.interval = setInterval(() => {
            const val = this.state.volume - volume_step;
            if (val <= 0) {
                clearInterval(this.interval);
                this.interval = null;
                this.props.nextVideo();
            }
            this.setVolume(val);
        }, interval);
    }

    fadeIn(target = 1) {
        this.setVolume(0);
        const duration = this.state.fadeDuration;//ms
        const interval = 100;//ms
        const volume_step = target / duration * interval;

        this.interval = setInterval(() => {
            const val = this.state.volume + volume_step;
            if (val >= 1) {
                clearInterval(this.interval);
                this.interval = null;
            }
            this.setVolume(val);
        }, interval);
    }

    currentVideo() {
        const current_video_id = this.props.video_id;
        let video;
        this.props.song.some(item => {
            if (item.YouTube === current_video_id) {
                video = item;
                return true;
            }
            return false;
        })
        return video;
    }

    getSabiDuration() {
        const current_video = this.currentVideo();
        if (!current_video) return;
        const sabi_raw = current_video['サビ'];

        if (!sabi_raw) return false;

        const sabi = sabi_raw.split('-').map(item => {
            const num = item.split(':').map(item => {
                return parseInt(item, 10);
            });

            let h = 0;
            let m = 0;
            let s = 0;
            if (num.length === 3) {
                h = parseInt(num[0], 10) * 60 * 60;
                m = parseInt(num[1], 10) * 60;
                s = parseInt(num[2], 10);
            }
            if (num.length === 2) {
                m = parseInt(num[0], 10) * 60;
                s = parseInt(num[1], 10);
            }
            if (num.length === 1) {
                s = parseInt(num[1], 10);
            }

            return parseInt(h, 10) + parseInt(m, 10) + parseInt(s, 10);

        });

        return {
            start: sabi[0],
            end: sabi[1],
        }
    }

    sabiMode(e) {
        if (!this.props.is_sabi_mode) return;
        const sabi = this.getSabiDuration();
        if (!sabi?.start || !sabi?.end) return;
        const playedSeconds = e.playedSeconds;
        const before_sabi = playedSeconds < sabi.start;
        const after_sabi = playedSeconds > sabi.end;
        const after_sabi_fadeout = playedSeconds > sabi.end + this.state.fadeDuration;
        if (before_sabi) {
            this.seek(sabi.start);
            return;
        }
        if (after_sabi) {
            if (!this.interval) {
                this.fadeOut();
            }
            return;
        }
        if (after_sabi_fadeout) {
            this.setVolume(0);
            this.props.nextVideo();
        }
    }

    _onProgress(e) {
        this.props.updatePlayed(e.played, e.playedSeconds);
        this.sabiMode(e);
    }

    _onStart(e) {
        if (this.state.volume <= 0) {
            this.fadeIn();
        }

        // const player = this.ReactPlayerRef.current.getInternalPlayer()['i'];

        // const a = () => {
        //     player.dispatchEvent(new CustomEvent("timeupdate"));
        //     window.requestAnimationFrame(a);
        // }
        // window.requestAnimationFrame(a)
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
        this.props.changeIsPlaying(true);
    }

    _onEnded() {
        this.props.nextVideo();
    }

    _onStateChange(e) {
        if (e.data === -1) {
            console.log('Change State: 未開始(-1)');
            return;
        }
        if (e.data === 0) {
            console.log('Change State: 終了(0)');
            console.log(this);
            this.props.nextVideo();
            return;
        }
        if (e.data === 1) {
            console.log('Change State: 再生中(1)');
            return;
        }
        if (e.data === 2) {
            console.log('Change State: 停止(2)');
            return;
        }
        if (e.data === 3) {
            console.log('Change State: バッファリング中(3)');
            return;
        }
        if (e.data === 5) {
            console.log('Change State: 頭出し済み(5)');
            return;
        }
    }

    changeVideo(id) {
        this.video_id = id;
    }
}

export default Player;
