import React from 'react';
import './kashi.scss';
import { convertHhMmSsToSeconds } from '../lib/misc';

class Kashi extends React.Component {
    constructor() {
        super();
        this.state = {
            current_index: 0,
        }
    }

    handleClickItem(e, s) {
        if (s < 0) return;
        this.props.seek(s);
    }

    renderItem(item, index, array) {
        let sentence = item.length === 2 ? item[1] : item[0];
        sentence = sentence || '●●●';

        // const current_sec = this.props.playedSeconds;
        const item_sec = item.length === 2 ? convertHhMmSsToSeconds(item[0]) : -1;
        const next_sec = (() => {
            if (array.length < index) return 0;
            if (array[index + 1]?.length !== 2) return 0;
            return convertHhMmSsToSeconds(array[index + 1][0])
        })();

        let is_after = false;
        if (this.props.playedSeconds > item_sec) is_after = true;

        let is_before = false;
        if (this.props.playedSeconds < next_sec) is_before = true;

        let is_active = false;
        if (is_before && is_after) {
            is_active = true;
        }

        return (
            <div
                key={index}
                className={is_active ? 'is-active kashi-item' : 'kashi-item'}
                onClick={e => this.handleClickItem(e, item_sec)}
            >
                {sentence}
            </div>
        )
    }

    renderKashi() {
        const kashi = this.props.song?.kashi;
        if (!kashi) return '';
        return kashi.map((item, index, array) => {
            return this.renderItem(item, index, array);
        })
    }

    render() {
        return (
            <div className="kashi-wrap">
                <div className="kashi-container">
                    {this.renderKashi()}
                </div>
            </div>
        )
    }
}

export default Kashi;
