import React from 'react';
import Player from './component/Player';
import SongList from './component/SongList';
import CollectionController from './component/CollectionController';
import Controller from './component/Controller';
import axios from 'axios';
import './App.scss';
import DataClient from './lib/DataClient';
import { playerInfo } from './lib/PlayerInfo';
import Splash from './component/Splash';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.playerInfo = playerInfo();
    this.state = {
      video_id: '',
      data: {
        status: 102,
      },
      song: [],
      current_video: '',
      is_sabi_mode: false,
      played: 0,
      playedSeconds: 0,
      duration: null,// current video duration
      is_playing: false,
    }
    this.getApi();
    this.playerRef = React.createRef();
    this.updatePlayed = this.updatePlayed.bind(this);
  }


  changeIsPlaying(is_playing = true) {
    this.setState({
      is_playing,
    })
  }

  toggleIsPlaying() {
    this.setState({
      is_playing: !this.state.is_playing,
    })
  }

  setDuration(duration) {
    this.setState({
      duration,
    })
  }

  updatePlayed(played, playedSeconds) {
    this.setState({
      played,
      playedSeconds,
    })
  }

  seek(s) {
    this.playerRef.current.seek(s);
  }

  changeSabiMode(is_sabi_mode) {
    this.setState(state => ({
      is_sabi_mode,
    }));
  }

  changeVideo(_video_id, _song) {
    const video_id = _video_id || this.playerInfo.defualt_video_id;
    const song = _song || this.state.song;
    const current_video = song.find(item => {
      if (item.YouTube === video_id) return true;
      return false;
    });
    this.setState(state => ({
      video_id,
      current_video,
      playing: true,
    }));
  }

  render() {
    return (
      <div className="App">
        <Splash
          video_id={this.state.video_id}
          playerInfo={this.playerInfo}
        ></Splash>
        <div className={this.state.video_id ? 'Main is-show' : 'Main'}>
          <Player
            className="Player"
            video_id={this.state.video_id}
            nextVideo={() => { this.nextVideo() }}
            ref={this.playerRef}
            song={this.state.song}
            is_sabi_mode={this.state.is_sabi_mode}
            updatePlayed={(played, playedSeconds) => { this.updatePlayed(played, playedSeconds) }}
            played={this.state.played}
            setDuration={(d) => { this.setDuration(d) }}
            toggleIsPlaying={e => { this.toggleIsPlaying(e) }}
            is_playing={this.state.is_playing}
            changeIsPlaying={(e) => this.changeIsPlaying(e)}
          ></Player>
          <CollectionController
            data={this.state.data}
            setCondition={conditions => this.setCondition(conditions)}
            resetCondition={() => this.resetCondition()}
            changeSabiMode={(is_sabi_mode) => this.changeSabiMode(is_sabi_mode)}
          ></CollectionController>
          <SongList
            className="SongList"
            song={this.state.song}
            video_id={this.state.video_id}
            changeVideo={(video_id) => { this.changeVideo(video_id) }}
          ></SongList>
          <Controller
            song={this.state.song}
            video_id={this.state.video_id}
            prevVideo={() => { this.prevVideo() }}
            nextVideo={() => { this.nextVideo() }}
            seekZero={() => { this.seekZero() }}
            played={this.state.played}
            playedSeconds={this.state.playedSeconds}
            updatePlayed={(played) => { this.updatePlayed(played) }}
            duration={this.state.duration}
            seek={(s) => { this.seek(s) }}
            is_playing={this.state.is_playing}
            toggleIsPlaying={e => { this.toggleIsPlaying(e) }}
          ></Controller>
        </div>
      </div>
    );
  }

  seekZero() {
    this.playerRef.current.seekZero();
  }

  getApi() {
    const url = this.playerInfo.spread_sheet_url;
    axios.get(url).then(res => {
      this.dataClient = new DataClient(res);
      const song = this.dataClient.song();
      this.setState(state => ({
        data: res,
        song,
      }));
      this.changeVideo(this.playerInfo.defualt_video_id, song);
    });
  }

  setCondition(conditions) {
    this.dataClient.setCondition(conditions);
    this.setState(state => ({
      song: this.dataClient.song(),
    }));
    console.log({
      conditions,
      song: this.dataClient.song(),
    })
  }

  resetCondition() {
    this.dataClient.resetCondition();
    this.setState(state => ({
      song: this.dataClient.song(),
    }));
    console.log({
      conditions: {
        filter: this.dataClient.filter,
        sort: this.dataClient.sort,
      },
      song: this.dataClient.song(),
    })
  }

  nextVideo() {
    const now_id = this.state.video_id;
    let now_index = -1;
    this.state.song.some((item, index) => {
      if (item.YouTube === now_id) {
        now_index = index;
        return true;
      }
      return false;
    });

    let next_index = 0;
    this.state.song.some((item, index) => {
      if (index <= now_index) return false;
      if (item?.YouTube) {
        next_index = index;
        return true;
      }
      return false;
    });
    this.changeVideo(this.state.song[next_index].YouTube);
  }

  prevVideo() {
    const now_id = this.state.video_id;

    let current_index = 0;
    this.state.song.some((item, index) => {
      if (item.YouTube === now_id) {
        current_index = index;
        return true;
      }
      return false;
    });
    if (current_index === 0) {
      current_index = this.state.song.length - 1;
    }
    this.changeVideo(this.state.song[current_index - 1].YouTube);
  }
}

export default App;
