import React from 'react';
import Kashi from './kashi';
import './SongDetail.scss';
import moment from 'moment';
import 'moment-timezone';
import { convertSecondsToHhMmSs } from '../lib/misc'

export default class SongDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            active_tab_index: 0,
        }
    }

    renderTab() {
        return (
            <div className="SongDetail__tabs">
                <div
                    className={this.state.active_tab_index === 0 ? 'SongDetail__tabs__item is-active' : 'SongDetail__tabs__item'}
                    onClick={() => { this.changeTab(0) }}
                >歌 詞</div>
                <div
                    className={this.state.active_tab_index === 1 ? 'SongDetail__tabs__item is-active' : 'SongDetail__tabs__item'}
                    onClick={() => { this.changeTab(1) }}
                >情 報</div>
            </div>
        )
    }

    renderInfoBody() {
        const song = this.getSongDetail();
        const detail = [
            ['曲名', (song['曲名'] || '(未登録)')],
            ['アーティスト', (song['メンバー'] || '(未登録)')],
            ['作曲', (song['作曲'] || '(未登録)')],
            ['編曲', (song['編曲'] || '(未登録)')],
            ['作詞', (song['作詞'] || '(未登録)')],
            ['公開日', (moment(song['音源公開日']).format('YYYY年MM月DD日') || '(未登録)')],
            ['長さ', (convertSecondsToHhMmSs(this.props.duration))]
        ].map((item, index) => {
            return <dl key={index}>
                <dt>{item[0]}</dt>
                <dd>{item[1]}</dd>
            </dl>
        });
        return <div className="Information">{detail}</div>;
    }

    renderBody() {
        if (this.state.active_tab_index === 0) {
            return <Kashi
                song={this.getSongDetail()}
                playedSeconds={this.props.playedSeconds}
                seek={s => this.props.seek(s)}
            />
        }
        if (this.state.active_tab_index === 1) {
            return this.renderInfoBody();
        }
    }

    render() {
        return (
            <div className="SongDetail">
                {this.renderTab()}
                {this.renderBody()}
            </div>
        )
    }

    changeTab(index) {
        this.setState({
            active_tab_index: index,
        })
    }

    getSongDetail() {
        if (!this.props?.song.length) return '';
        const song = this.props.song.find(item => item.YouTube === this.props.video_id);
        return song;
    }
}
