import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DocumentMeta from 'react-document-meta';
import { playerInfo } from './lib/PlayerInfo';

const player_name = playerInfo().player_name;

const meta = {
  title: player_name,
  meta: {
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1, viewport-fit=cover',
    // 'http-equiv': {
    //   'cache-control': 'no-cache',
    //   'expires': 0,
    //   'pragma': 'no-cache',
    // },
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DocumentMeta {...meta}>
    <App />
  </DocumentMeta>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
